import * as React from "react";
import logo from "../images/logo.svg";
import { Link } from "@reach/router";
// import { isLoggedIn } from "../_services/auth1"
import { AuthService } from "../_services/auth.service";
import UserComponent from "../widgets/Header/User-Componen";
import { useEffect } from "react";
import { useLocation } from "@reach/router";

const Header = (props) => {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoggedIn(AuthService.isLoggedIn());
  }, [location]);

  const getSidebarToggle = () => {
    props?.sidebarToggle();
  };

  return (
    <header className="header flex-0">
      <div className="px-2 px-lg-5 d-flex justify-content-between align-items-center">
        {loggedIn && (
          <div
            className="menu-toggle me-4  d-lg-none"
            onClick={getSidebarToggle}
          >
            <i className="fa fa-bars"></i>
          </div>
        )}

        <div className="logo-tags me-3 me-lg-0">
          <Link to="/" className="me-2">
            <img src={logo} className="logo" alt="logo" />
          </Link>
          <label className="fs-20 fw-600">Test Automation Framework</label>
        </div>

        <div className="actions d-flex align-items-center">
          {!loggedIn ? (
            <Link className="btn btn-primary" to="/app/login">
              Login
            </Link>
          ) : (
            <UserComponent />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
