import * as React from "react";
import { Link } from "gatsby";
const Footer = () => (
  <footer className="footer flex-0">
    <div className="px-2 px-lg-5 d-flex justify-content-between align-items-center">
      <p className="copyright">&copy; Keyloop</p>

      <div className="footer-link">
        {footerSubMenu.map(function (link, index) {
          return (
            <Link key={index} to={link.id} target={link.description=="Report portal"?"_blank":"_self"}>
              {link.description}
            </Link>
          );
        })}
      </div>
    </div>
  </footer>
);
const footerSubMenu = [
  {
    id: "/",
    description: "About Framework",
  },
  {
    id: "/documentation",
    description: "Getting started",
  },
  {
    id: `${process.env.REPORT_PORTAL_URL}`,
    description: "Report portal",
  },
];

export default Footer;
