import React from "react";
import { Card } from "react-bootstrap";
import "./styleModel.scss";

const ErrorPopup = (props) => {
  const data = JSON.parse(props.data);

  return (
    <div className="popup-error">
      <div className="popup_inner">
        <Card className="bg-white custom-card" style={{ border: 0 }}>
          <Card.Header className="bg-gray">
            <div className="row">
              <div className="col-12">
                <Card.Title className="card-title fs-22">
                  {data.title}
                </Card.Title>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="row">
              <div className="col-12">{data.message}</div>
              {data?.description && (
                <div className="col-12 ">{data.description}</div>
              )}
              <div className="col-12 text-end mt-3">
                <button className="btn btn-primary" onClick={props.closePopup}>
                  Ok
                </button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ErrorPopup;
