// ErrorHandelingModel.js
import { action } from "easy-peasy";
// , computed, createContextStore, thunk
const ErrorHandelingModel = {
  error: null,
  setView: action((state, payload) => {
    state.error = payload;
  }),
};

export default ErrorHandelingModel;
