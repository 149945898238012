import api from "./api";
import { FETCH_LIMIT } from "../constants/app-constants";

export const userService = {
  getUser,
  getAllUser,
  deleteUser,
  createUser,
  updateUser,
};

function getAllUser(param: any) {
  // https://api.kltaf.frizbane.io/api/users?q=dsadsa&kind=admin
  let q = param.q ? `&q=${param.q}` : "";
  let kind = param.kind ? `&kind=${param.kind}` : "";
  let limitParam = param?.limit
    ? "limit=" + param?.limit + "&"
    : "limit=" + FETCH_LIMIT + "&";
  let offsetParam = param?.offset ? "offset=" + param?.offset : "offset=0";
  return api.get(`api/users?${limitParam}${offsetParam}${q}${kind}`);
}

function getUser(req) {
  return api.get(`api/users/${req}`);
}

function deleteUser(req) {
  return api.delete(`api/users/${req}`);
}

function createUser(data: any) {
  return api.post(`api/users`, data).then((response) => {
    return response;
  });
}

function updateUser(username: any, data: any) {
  return api.put(`api/users/${username}`, data).then((response) => {
    return response;
  });
}
