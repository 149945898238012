import { createStore } from "easy-peasy";

import AutherizationStore from "./AutherizationStore";
import BuildsStore from "./BuildsStore";
import ErrorHandelingStore from "./ErrorHandelingStore";

const GlobalStore = createStore({
  auth: AutherizationStore,
  build: BuildsStore,
  errorHandel: ErrorHandelingStore,
});

export default GlobalStore;
