import React from "react";
// import { Link } from '@reach/router';
import { Link } from "gatsby";
import { AuthService } from "../_services/auth.service";


const NavItem = (props) => {
  const { icon, link, title, page } = props.item;
  let path = window.location.pathname.split("/");
  let pathLinks = link.split("/")[2];

  const getSidebarToggle = () => {
    props?.sidebarToggle();
  };


    const isOwnAnyProduct =(page) =>{
      if(page != "dashboard")
       return true
      else{
        let user = AuthService.getCurrentUser()
        let flag = false;
        user?.roles.forEach((item,index)=>{
          if(item?.role == "Owner"){
            flag = true
          }
        })
        return flag
      }
     
    }
  return (
    <div onClick={getSidebarToggle}>
      {
       AuthService.hasAccess(page) && isOwnAnyProduct(page) ? (
        <Link
          to={link}
          className={
            path.indexOf(pathLinks) > -1 ? "navItem active" : "navItem"
          }
          activeClassName="active"
        >
          <i className={icon + " navIcon"} aria-hidden="true"></i>
          <span className="navLabel">{title} </span>
        </Link>
      ):null
    }
    </div>
  );
};

export default NavItem;
