import PropTypes from "prop-types";
import * as React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../styles/common.scss";
import NavigationSideBar from "./NavigationSideBar";
import { AuthService } from "../_services/auth.service";
import { useEffect } from "react";
import { useLocation } from "@reach/router";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import favicon from "../images/favicon.ico";
import { Helmet } from "react-helmet";
import { StoreProvider } from "easy-peasy";
import GlobalStore from "../store/GlobalStore";

const Layout = ({ children }) => {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [showSidebar, setSidebar] = React.useState(false);
  const [path, setPath] = React.useState([]);
  const location = useLocation();
  // let path = window.location.pathname.split("/");

  useEffect(() => {
    setLoggedIn(AuthService.isLoggedIn());
    setPath(window.location.pathname.split("/"));
  }, [location]);

  const sidebarToggle = () => {
    setSidebar(!showSidebar);
  };

  return (
    <StoreProvider store={GlobalStore}>
      <Helmet>
        {/* <script src={withPrefix("script.js")} type="text/javascript" /> */}
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header sidebarToggle={sidebarToggle} />
      {path && (
        <div
          className={
            !loggedIn
              ? path[2] === "login" 
                ? "d-flex align-items-center justify-content-center fullheight"
                : "pt-70 pb-20"
              :  (
                  path[2] === "change-password"? 
                  "d-flex align-items-center justify-content-center fullheight":
                  "d-flex pt-70 fullheight"
                  
                 )
          }
        >
          {/* && path[1] */}
          {loggedIn && path[2] !== "change-password" ? (
            <NavigationSideBar
              showSidebar={showSidebar ? true : false}
              sidebarToggle={sidebarToggle}
            />
          ) : (
            ""
          )}
          <div className={
            !loggedIn ? "ps-0" : 
                path[2] == "change-password"? "ps-0" : 
               "containWrap"
            }>
            {children}
          </div>
        </div>
      )}
      <ToastContainer
        autoClose={4000}
        transition={Slide}
        draggable={false}
        newestOnTop={true}
        hideProgressBar={true}
      />
      <Footer />
    </StoreProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
