// BuildsModel.js
import { action } from "easy-peasy";

const BuildsModel = {
  view: null,
  setView: action((state, payload) => {
    state.view = payload;
  }),
};

export default BuildsModel;
