// AutherizationStore.js
import { action } from "easy-peasy";

const AutherizationStore = {
  userData: null,
  setAuth: action((state, payload) => {
    state.userData = payload;
  }),
};

export default AutherizationStore;
