import React, { useEffect, useState, useRef } from "react";
import { AuthService } from "../../_services/auth.service";
import { navigate } from "@reach/router";
import { userService } from "../../_services/user.service";
import ErrorPopup from "../Popups/Error-Popup";
import "./styleHeader.scss";
import { useStoreState, useStoreActions } from "easy-peasy";

const UserComponent = (props) => {
  const stateStore = useStoreState((state) => state?.auth?.userData);
  const stateAction = useStoreActions((actions) => actions?.auth?.setAuth);

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
  });

  const [showBox, setShowBox] = useState(false);
  const [userName, setUserName] = useState("");
  const ref = useRef(null);
  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setShowBox(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowBox(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    AuthService.isLoggedIn && !stateStore?.first_name && !stateStore?.last_name
      ? findUsername()
      : setUser(stateStore);
  }, []);

  function findUsername() {
    let k = AuthService.getUsername();

    userService.getUser(k).then((respUser) => {
      if (respUser.status == 200) {
        stateAction(respUser?.data);
        setUser(respUser?.data);
      }
    });
  }

  function setUser(data) {
    let uname: any;
    stateStore?.first_name && stateStore?.last_name
      ? (uname = stateStore?.first_name + " " + stateStore?.last_name)
      : (uname = data?.first_name + " " + data?.last_name);

    setUserName(uname);
  }

  function toggleOpen() {
    setShowBox(!showBox);
  }

  function closeError() {
    setError({ ...error, errorStatus: false });
    window.localStorage.removeItem("localUser");
    navigate(`/app/login`);
  }

  return (
    <>
      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}

      <div className="d-flex align-items-center position-relative" ref={ref}>
        <a className="no-link d-flex align-items-center" onClick={toggleOpen}>
          <i className="fa fa-user-circle fa-2x me-2" aria-hidden="true"></i>

          <label
            style={{ textTransform: "capitalize" }}
            className="d-none d-md-block"
          >
            Hello, {userName}
          </label>
          <i className="fa fa-angle-down ms-2" aria-hidden="true"></i>
        </a>
        {/* <div className="arrow" ></div> */}
        {showBox ? (
          <div className="dropBox-wrap arrow-top">
            <ul className="dropBox">
              <li className="droplist d-md-none">
                <label style={{ textTransform: "capitalize" }}>
                  {userName}
                </label>
              </li>
              <li className="droplist">
                <a
                  href="/"
                  className=""
                  onClick={(event) => {
                    event.preventDefault();
                    AuthService.logout() && navigate(`/app/login`);
                  }}
                >
                  <i className="fa fa-sign-out" aria-hidden="true"></i> logout
                </a>
              </li>
              <li className="droplist">
                <a
                  href="/"
                  className=""
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(`/app/change-password`);
                    // event.preventDefault();
                    // AuthService.logout() && navigate(`/app/change-password`);
                  }}
                >
                  <i className="fa fas fa-lock" aria-hidden="true"></i> Change
                  password
                </a>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default UserComponent;
